export const GAME_ID = 601
export const PUBLIC_API_URL = 'https://api.test.mod.io/v1'
export const PUBLIC_API_KEY = '415272f7bbf51d1feb93fb16e70c9302'
export const PRIMARY_COLOR = '#34c6ff'
export const DARK_COLOR = '#0e2239'
export const LIGHT_COLOR = '#ffffff'
export const DARK_MODE = true
export const TAG_FILTER = null
export const showLegal = false
export const hostURL = '*'
